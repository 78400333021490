import type Application from '@ember/application'
import {
  importSync,
  isDevelopingApp,
  isTesting,
  macroCondition,
} from '@embroider/macros'

/**
 * Initialize msw in development by deferring application startup.
 */
export function initialize(application: Application) {
  application.deferReadiness()

  void setupMsw().then(() => {
    application.advanceReadiness()
  })
}

export default {
  initialize,
}

async function setupMsw() {
  if (macroCondition(isTesting())) {
    // Do not start MSW in a test environment
  } else if (macroCondition(isDevelopingApp())) {
    // Start MSW during development
    const { worker } = importSync('re-client/mocks') as {
      worker: { start: () => Promise<void> }
    }
    await worker.start()
  }
}
