import type Owner from '@ember/owner'
import Service, { inject as service } from '@ember/service'
import type Store from '@ember-data/store'
import { ContentLoader, activityAccent } from '@blakeelearning/content-loader'
import type { Manifest } from '@blakeelearning/content-loader-core'
import config from 're-client/config/environment'

export default class CaperLoaderService extends Service {
  @service
  declare store: Store

  loader: ContentLoader

  constructor(owner: Owner) {
    super(owner)

    this.loader = new ContentLoader({
      framework: 'caper',
      config: config.contentLoader.caper,
    })
  }

  load(manifests: Manifest[], variables?: Record<string, unknown>) {
    return this.loader.load(manifests, variables)
  }

  setAccent(accent: string) {
    const variant = activityAccent(accent)

    if (this.loader.variant !== variant) {
      this.loader = new ContentLoader({
        variant,
        framework: 'caper',
        config: config.contentLoader.caper,
      })
    }
  }

  avatarJsonRequest() {
    const adapter = this.store.adapterFor('application')
    const url = adapter.buildURL('avatar', 'avatar_content')
    return this.loader.manifestRequest(url, {
      credentials: 'include',
    })
  }

  changingRoomRequest() {
    const adapter = this.store.adapterFor('application')
    const url = adapter.buildURL('avatar', 'changing_room_content')
    return this.loader.manifestRequest(url, {
      credentials: 'include',
    })
  }

  readingBookRequest(bookId: string | undefined) {
    const adapter = this.store.adapterFor('reading/book')
    const url = adapter.buildURL('reading/book', bookId)
    return this.loader.manifestRequest(url, {
      credentials: 'include',
    })
  }

  storyFactoryAssetRequest(id: 'current' | 'previous' | 'voting') {
    const adapter = this.store.adapterFor('application')
    const url = adapter.buildURL('story-factory-contest', id)
    return this.loader.manifestRequest(url, {
      credentials: 'include',
    })
  }

  storyFactoryEntriesRequest() {
    const adapter = this.store.adapterFor('application')
    const url = adapter.buildURL('story-factory-entries')
    return this.loader.manifestRequest(url, {
      credentials: 'include',
    })
  }
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:caper-loader')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('caper-loader') declare altName: CaperLoaderService;`.
declare module '@ember/service' {
  interface Registry {
    'caper-loader': CaperLoaderService
  }
}
